import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthContext';
import googleMeet from '../assets/img/icon/google-meet.svg';
import zoom from '../assets/img/icon/zoom.svg';
import microsoftTeams from '../assets/img/icon/microsoft-teams.svg';
import record from "../assets/img/icon/bot-btn-icon.svg";
import { showToast } from '../components/common/toast';
import recordPng from "../assets/img/icon/record-btn-icon.svg"

import { useNavigate, useLocation, Link } from "react-router-dom";
import Files from './Files/Files';
import { t } from '../components/common/translation'
import { useModal } from '../components/common/Modal/ModalContext';
import { set } from 'react-hook-form';
import CalendarBody from '../components/calendar_body';
import { get } from 'jquery';
import calendarImageJpg from '../assets/img/calendar-meeting-img.jpg';
import MeetingtorOptionsModal from '../components/common/meetingtorOptionsModal';
import MeetingBox from '../components/meeting_box';
import LanguageDropdown from '../components/common/transcribeLanguageDropdown';
import MeetingGuide from '../components/meeting_guide';

function Dashboard() {

  const navigate = useNavigate();
  const { showModal } = useModal();
  const [meetings, setAllMeetings] = useState([]);
  const { hashedId, userData, planInfo } = useAuth();
  const [addAssistantInProgrees, setAddAssistantInProgress] = useState(false);
  const [noCalendar, setNoCalendar] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [linkedCalendars, setLinkedCalendars] = useState([]);
  const [meetingsFirstLoading, setMeetingsFirstLoading] = useState(true);
  const [calendarsFirstLoading, setCalendarsFirstLoading] = useState(true);
  const [meetingsLoading, setMeetingsLoading] = useState(false);
  const [showMeetingtorOptionsModal, setShowMeetingtorOptionsModal] = useState(false);
  const [credits, setCredits] = useState(30);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [transcriptionLanguage, setTranscriptionLanguage] = useState(localStorage.getItem("transcript-language") || "EN");
  const [autoTranscribeManualMeeting, setAutoTanscribeManualMeeting] = useState(false);


  useEffect(() => {
    if (planInfo) {
      setCredits(planInfo.allowed - planInfo.total_orders);
    }
  }, [planInfo]);


  useEffect(() => {
    if (queryParams.get('calendarlinked') && hashedId) {
      setMeetingsFirstLoading(false);
      onCalendarConnected();
    }
  }, [queryParams.get('calendarlinked'), hashedId]);

  useEffect(() => {
    if (!userData) {
      return;
    }
    if (!localStorage.getItem("transcript-language"))
      findLanguage();
    if (!localStorage.getItem("isLanguageSet")) {
      localStorage.setItem("isLanguageSet", true);
      showModal("LANGUAGE-MODAL");

    }
  }, [userData]);

  const onAutoTranscribeChange = () => {
    const val = !autoTranscribeManualMeeting;
    if (userData.SStatus === "Trial") {
      showModal("UPGRADE-TRANSCRIBE");

    } else {
      setAutoTanscribeManualMeeting(val);
    }
  }


  const findLanguage = async () => {
    try {
      const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
      const [iso639_1, countryCode] = browserLanguage.split("-");

      const res = await fetch("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/getusersettings?hashed_id=" + hashedId);
      const data = await res.json();

      const transcripLanguage = data?.user_settings.meetingtor_auto_transcribe_language || userData?.mylanguage

      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || 'US');
      localStorage.setItem("transcript-language", transcripLanguage || browserLanguage);

    } catch (error) {
      console.error('Error detecting browser language:', error);
    }
  };

  const onCalendarConnected = async () => {
    setCalendarLoading(true);
    const res = await getLinkedCalendars();
    if (!res) {
      //wait for 10 seconds
      setTimeout(() => {
        getAllMeetings();
      }, 10000);
    }
  }

  const onCalendarDisconnected = async () => {
    setCalendarLoading(true);
    const res = await getLinkedCalendars();
    if (!res) {
      getAllMeetings();
    }
  }


  useEffect(() => {
    if (!queryParams.get('calendarlinked')) {
      getAllMeetings();
    }
  }, [hashedId]);

  const addAssistantManually = async (url) => {
    if (addAssistantInProgrees) {
      return;
    }
    if (!url || url.length === 0) {
      return;
    }

    if (credits <= 0 && userData.SStatus === "Trial") {
      showModal("UPGRADE-RECORD");
      return;
    }

    setAddAssistantInProgress(true);
    try {
      const response = await fetch('https://05kevs6xq8.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-InsertAssistantManually', {
        method: 'POST',
        headers: {
        },
        body: JSON.stringify({
          "meetingUrl": url, "uid": hashedId, "auto_transcribe": autoTranscribeManualMeeting
        })
      });

      if (!response.ok) {
        const data = await response.json();
        if (response.status === 406) {

          if (data.limitSurpassed) {
            showModal("UPGRADE-RECORD");
          } else {
            showToast(data.message, { type: "error" });
          }
        } else {
          showToast(data.message, { type: "error" });
        }
      } else {
        showToast((t("Assistant added successfully!")), { type: "success" });
      }

      setAddAssistantInProgress(false);
      console.log(response);
      document.getElementById('urlInput').value = "";
      setAutoTanscribeManualMeeting(false);

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getLinkedCalendars();
  }, [hashedId]);

  const getLinkedCalendars = async () => {
    if (!hashedId) {
      return;
    }
    fetch(`https://y3gq4fuoaf.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-GetLinkedCalendars?uid=${hashedId}`).then(res => res.json()).then((result) => {
      setNoCalendar(result?.calendars?.length === 0);
      let calendars = result?.calendars;

      setLinkedCalendars(calendars);
      setCalendarsFirstLoading(false);
      return noCalendar;
    }).catch((error) => {
      console.log(error);
    });
    return false;
  };


  const getAllMeetings = async () => {
    if (hashedId) {
      setMeetingsLoading(true);
      fetch(`https://h03etj2vf7.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-GetUpcomingMeetings?uid=${hashedId}`).then(res => res.json()).then((result) => {
        var groupedMeetings = groupMeetingsByDate(result?.meetings);
        setAllMeetings(groupedMeetings);
        setMeetingsLoading(false);
        setMeetingsFirstLoading(false);
      }).catch((error) => { });
      setCalendarLoading(false);
    }
  };


  const groupMeetingsByDate = (meetings) => {
    if (meetings.length === 0) {
      return {};
    }

    // sort meetings by date
    meetings.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    const minDate = new Date(meetings[0].startDate);
    const maxDate = new Date(meetings[meetings.length - 1].startDate);

    const acc = {};

    const currentDate = new Date(minDate.setHours(0, 0, 0, 0));
    const lastDate = new Date(maxDate.setHours(0, 0, 0, 0));

    while (currentDate <= lastDate) {
      const date = currentDate.toLocaleDateString() + ' ' + getDayName(currentDate);

      acc[date] = [];
      currentDate.setDate(currentDate.getDate() + 1);
    }

    meetings.forEach(meeting => {
      const date = new Date(meeting.startDate).toLocaleDateString() + ' ' + getDayName(new Date(meeting.startDate));
      acc[date].push(meeting);

    });
    return acc;
  };

  const getDayName = (inputDate) => {
    //if today return today
    const today = new Date();
    if (inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()) {
      return t("Today");
    }
    const dayNames = [t("Sunday"), t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday")];
    const dayIndex = inputDate.getDay();
    return dayNames[dayIndex];
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };



  return (
    <>
      <MeetingtorOptionsModal showMeetingtorOptionsModal={showMeetingtorOptionsModal} getAllMeetings={getAllMeetings}
        hideMeetingtorOptionsModal={() => setShowMeetingtorOptionsModal(false)} credits={credits} />
      {!isMobileDevice() &&

        <div class="row d-flex">
          {(calendarsFirstLoading || meetingsFirstLoading)
            ?
            <div className="col-lg-8 card-margin">
              <div className='card card-padding'>
                <br />
                <div className='d-flex justify-content-center text-center'>
                  <span class="spinner-border text-primary"></span>
                </div>
              </div>
            </div>
            : <>
              <div class={noCalendar ? "col-lg-8 card-margin" : "col-lg-4 card-margin"} >
                <div class="card h-100"
                  style={{
                    maxHeight: '610px',
                    overflowY: 'scroll'
                  }}>

                  <CalendarBody
                    onCalendarDisconnected={onCalendarDisconnected}
                    linkedCalendars={linkedCalendars}
                    credits={credits} isTrial={userData.SStatus === "Trial"}
                  />
                </div>
              </div>
              <div class="col-lg-4 card-margin" style={{ display: (noCalendar) ? 'none' : null }}>
                <div className='card card-padding card-100'   >
                  {
                    meetingsLoading
                      ? <>
                        <br />
                        <div className='d-flex justify-content-center text-center'>
                          <span class="spinner-border text-primary"></span>
                        </div>
                      </>
                      :
                      calendarLoading ?
                        <>
                          <br />
                          <div className='d-flex justify-content-center text-center'>
                            <span class="spinner-border text-primary"></span>
                          </div>
                          <br />
                          <p class="text-center">{t("Please wait for just a few seconds. Your calendar is currently syncing.")}</p>
                        </>
                        :
                        (Object.keys(meetings).length == 0) ?
                          (
                            <div className='card-padding'>
                              {linkedCalendars &&
                                <p class="text-center">{t("No meetings have been found. Please link the calendar in which you schedule your meetings.")}</p>}
                            </div>
                          )
                          : (
                            <ul className="meeting-list" style={{
                              listStyleType: 'none',
                              paddingLeft: '0',
                              maxHeight: '550px', // Ensure it takes up at most 100% of the parent height
                              overflowY: 'scroll'
                            }}>
                              {Object.entries(meetings).map(([date, meetingsOnDate], index) => (
                                <li key={date}>
                                  <div className='d-flex justify-content-between mb-2'>
                                    <p className='ft-18-title dark-color mb-2'>{date}</p>
                                    {index === 0 && (
                                      <span class="me-2 pointer" onClick={
                                        () =>
                                          setShowMeetingtorOptionsModal(true)
                                      } >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                          <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                                          <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                                        </svg>
                                      </span>
                                    )}
                                  </div>
                                  {meetingsOnDate.length > 0 ? (
                                    meetingsOnDate.map((meeting, index) => (
                                      <MeetingBox meeting={meeting} index={index} credits={credits} isTrial={userData.SStatus === "Trial"} />
                                    ))
                                  )
                                    : (
                                      <div className='calendar-meeting-box small-meeting-box'>
                                        <p className='text-center mb-0'>{t("No events today")}</p>
                                      </div>
                                    )}
                                </li>
                              ))}
                            </ul>)}
                </div>
              </div>
            </>}
          <div class="col-lg-4 card-margin">
            <div class="card-margin">
              <div class="card card-padding overflow-visible ">
                <p style={{ "lineHeight": 1, "marginBottom": 10 }} class="ft-18-title text-center">{t('Record Live Meeting')} </p>
                <p style={{ "lineHeight": 1.2, "marginBottom": 10 }} class='text-center' >{t('Enter the URL of live meeting to add meeting recorder now.')}</p>
                <div class="img-icon-list" style={{ "height": 44 }}>
                  <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Meet"><img src={googleMeet} class="img-fluid" alt="img" /></span>
                  <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Microsoft Teams"><img src={microsoftTeams} class="img-fluid" alt="img" /></span>
                  <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img" /></span>
                </div>
                <div class="mb-2">
                  <input type="text" id="urlInput" class="form-control round-input border-input" placeholder={t("Enter URL")} />
                </div>
                {/*
                <div className=" d-flex flex-row justify-content-between">
                  <div class="checkbox custom mt-2 mb-3">
                    <label class='light-color'><input type="checkbox" onClick={(e) => onAutoTranscribeChange()} checked={autoTranscribeManualMeeting} />{t("Transcribe the meeting")}</label>
                  </div>
                  {autoTranscribeManualMeeting && (
                    <div class="form-group mt-0 mb-0 light-color" style={{ width: '200px' }} >
                      <LanguageDropdown onLanguageChange={setTranscriptionLanguage} light={true} />
                    </div>
                    //  <div className="d-flex flex-row">
                    //    <p class='me-2'> {t("Meeting language: ")}</p>
                    //    <p className='theme-color underline pointer'> {transcriptionLanguage} </p>
                    //  </div>
                  )}
                </div>
      */}
                <div class="text-center">

                  {addAssistantInProgrees ? <div className='d-flex justify-content-center text-center'>
                    <a href="javascript:void(0);" class="btn theme-btn light-btn loading" id="connect-btn"><span class="spinner"></span></a>
                  </div>
                    : <button class="btn theme-btn rounded-btn"
                      onClick={() => addAssistantManually(document.getElementById('urlInput').value)}
                    >
                      <span class="me-2"><img src={recordPng} class="img-fluid" width="15px" alt="img" /></span>{t("Recording")} </button>
                  }

                </div>
              </div>
            </div>

            <div className='card card-padding-small '>
              <Files isFile={false} hideOptions={true} />
            </div>

          </div>
          <MeetingGuide />
        </div>
      }
      {isMobileDevice() && <div class="row d-flex">
        <div class="col-lg-4 card-margin">
          <div class="card-margin">
            <div class="card card-padding overflow-visible ">
              <p style={{ "lineHeight": 1, "marginBottom": 10 }} class="ft-18-title text-center">{t('Record Live Meeting')} </p>
              <p style={{ "lineHeight": 1.2, "marginBottom": 10 }} class='text-center' >{t('Enter the URL of live meeting to add meeting recorder now.')}</p>
              <div class="img-icon-list" style={{ "height": 44 }}>
                <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Meet"><img src={googleMeet} class="img-fluid" alt="img" /></span>
                <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Microsoft Teams"><img src={microsoftTeams} class="img-fluid" alt="img" /></span>
                <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img" /></span>
              </div>
              <div class="mb-2">
                <input type="text" id="urlInput" class="form-control round-input border-input" placeholder={t("Enter URL")} />
              </div>
               {/*
              <div className=" d-flex flex-row justify-content-between">
                <div class="checkbox custom mt-2 mb-3">
                  <label class='light-color'><input type="checkbox" onClick={(e) => onAutoTranscribeChange()} checked={autoTranscribeManualMeeting} />{t("Transcribe the meeting")}</label>
                </div>
                {autoTranscribeManualMeeting && (
                  <div class="form-group mt-0 mb-0 light-color" style={{ width: '200px' }} >
                    <LanguageDropdown onLanguageChange={setTranscriptionLanguage} light={true} />
                  </div>
              
                )}
              </div> */}
              <div class="text-center">

                {addAssistantInProgrees ? <div className='d-flex justify-content-center text-center'>
                  <a href="javascript:void(0);" class="btn theme-btn light-btn loading" id="connect-btn"><span class="spinner"></span></a>
                </div>
                  : <button class="btn theme-btn rounded-btn"
                    onClick={() => addAssistantManually(document.getElementById('urlInput').value)}
                  >
                    <span class="me-2"><img src={recordPng} class="img-fluid" width="15px" alt="img" /></span>{t("Recording")} </button>
                }

              </div>
            </div>
          </div>

          <div className='card card-padding-small '>
            <Files isFile={false} hideOptions={true} />
          </div>

        </div>

        {(calendarsFirstLoading || meetingsFirstLoading)
          ?
          <div className="col-lg-8 card-margin">
            <div className='card card-padding'>
              <br />
              <div className='d-flex justify-content-center text-center'>
                <span class="spinner-border text-primary"></span>
              </div>
            </div>
          </div>
          : <>

            <div class="col-lg-4 card-margin" style={{ display: (noCalendar) ? 'none' : null }}>
              <div className='card card-padding card-100'   >
                {
                  meetingsLoading
                    ? <>
                      <br />
                      <div className='d-flex justify-content-center text-center'>
                        <span class="spinner-border text-primary"></span>
                      </div>
                    </>
                    :
                    calendarLoading ?
                      <>
                        <br />
                        <div className='d-flex justify-content-center text-center'>
                          <span class="spinner-border text-primary"></span>
                        </div>
                        <br />
                        <p class="text-center">{t("Please wait for just a few seconds. Your calendar is currently syncing.")}</p>
                      </>
                      :
                      (Object.keys(meetings).length == 0) ?
                        (
                          <div className='card-padding'>
                            {linkedCalendars &&
                              <p class="text-center">{t("No meetings have been found. Please link the calendar in which you schedule your meetings.")}</p>}
                          </div>
                        )
                        : (
                          <ul className="meeting-list" style={{
                            listStyleType: 'none',
                            paddingLeft: '0',
                            maxHeight: '550px', // Ensure it takes up at most 100% of the parent height
                            overflowY: 'scroll'
                          }}>
                            {Object.entries(meetings).map(([date, meetingsOnDate], index) => (
                              <li key={date}>
                                <div className='d-flex justify-content-between mb-2'>
                                  <p className='ft-18-title dark-color mb-2'>{date}</p>
                                  {index === 0 && (
                                    <span class="me-2 pointer" onClick={
                                      () =>
                                        setShowMeetingtorOptionsModal(true)
                                    } >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                                        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                                {meetingsOnDate.length > 0 ? (
                                  meetingsOnDate.map((meeting, index) => (
                                    <MeetingBox meeting={meeting} index={index} credits={credits} isTrial={userData.SStatus === "Trial"} />
                                  ))
                                )
                                  : (
                                    <div className='calendar-meeting-box small-meeting-box'>
                                      <p className='text-center mb-0'>{t("No events today")}</p>
                                    </div>
                                  )}
                              </li>
                            ))}
                          </ul>)}
              </div>
            </div>
            <div class={noCalendar ? "col-lg-8 card-margin" : "col-lg-4 card-margin"} >
              <div class="card h-100"
                style={{
                  maxHeight: '610px',
                  overflowY: 'scroll'
                }}>

                <CalendarBody
                  onCalendarDisconnected={onCalendarDisconnected}
                  linkedCalendars={linkedCalendars}
                  credits={credits} isTrial={userData.SStatus === "Trial"}
                />
              </div>
            </div>
          </>}

        <MeetingGuide />

      </div>}
    </>
  );
}

export default Dashboard;